import { logisticsService } from '@/api/';
import { DialogMixin } from '@/mixins/dialog';
import { DeliveryModeEnum } from '@/resource/enum';
import { AddLogisticsParams, SaveLogistics } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
type LogisticsForm = Pick<SaveLogistics, 'sendRemark' | 'senderType' | 'shopCount'>;
@Component({
  components: {}
})
export default class AddLogistics extends mixins(DialogMixin) {
  @Prop({ type: Object, required: true })
  public readonly deliveryParams!: AddLogisticsParams;

  public form: Partial<LogisticsForm> = {
    sendRemark: this.deliveryParams.deliveryNoticeCode.split('-').shift(),
    senderType: this.deliveryParams.sendMethod ?? DeliveryModeEnum.TPL,
    shopCount: undefined
  };

  public deliveryModes: Array<{ label: string; value: string }> = [];

  public formRules: { [p in keyof Partial<LogisticsForm>]: Array<object> } = {
    senderType: [
      {
        required: true,
        validator: (rule: any, value: DeliveryModeEnum, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('logistics.selectDelivertMode')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    shopCount: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value || value === 0) {
            callback(new Error(translation('logistics.inputChildCount')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  public created(): void {
    this.deliveryModes = logisticsService.getDeliveryMode();
  }

  public submit(): void {
    (this.$refs.form as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      const logistics = Object.assign(this.form, this.deliveryParams);
      this.setLoading(true);
      try {
        await logisticsService.post(logistics as SaveLogistics);
        Message.success(translation('operationRes.addSuccess'));
        this.$emit('add-success');
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
}
